// extracted by mini-css-extract-plugin
export var approachInfoRoundBlock__between = "c1_w2";
export var approachInfoRoundBlock__card = "c1_w1";
export var approachInfoRoundBlock__contentFive = "c1_xc";
export var approachInfoRoundBlock__contentFour = "c1_xb";
export var approachInfoRoundBlock__contentOne = "c1_w7";
export var approachInfoRoundBlock__contentSix = "c1_xd";
export var approachInfoRoundBlock__contentThree = "c1_w9";
export var approachInfoRoundBlock__contentTwo = "c1_w8";
export var approachInfoRoundBlock__description = "c1_w6";
export var approachInfoRoundBlock__icon = "c1_w4";
export var approachInfoRoundBlock__imageDesktop = "c1_w3";
export var approachInfoRoundBlock__title = "c1_w5";