// extracted by mini-css-extract-plugin
export var agileApproachSection = "j_J";
export var agileApproachSection__testimonial = "j_K";
export var bestPracticesApproachSection = "j_L";
export var caseApproachSection = "j_T";
export var processApproachSection = "j_Q";
export var projectSuccessApproachSection = "j_M";
export var recentArticlesApproachSection = "j_S";
export var successStoriesApproachSection = "j_V";
export var techStackApproachSection = "j_R";
export var testimonialApproachSection = "j_N";
export var toolslApproachSection = "j_P";